module.exports = [{
      plugin: require('/Users/mkpro1/Documents/Projects/IsMyDateSafe/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ismydatesafe.com"},
    },{
      plugin: require('/Users/mkpro1/Documents/Projects/IsMyDateSafe/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mkpro1/Documents/Projects/IsMyDateSafe/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/mkpro1/Documents/Projects/IsMyDateSafe/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    }]
